/* A content section comprised only of a header and one or
 * more paragraphs of text.
 * Supported props: src
*/

import React from 'react';

export default function BasicSection(props) {
  const cfg = props.src;
  const sectionStyle = `basic-section section-indented ${cfg.theme||''}`.trim();
  const bodyStyle = `col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2${addclass(cfg.bodyClass)}`.trim();
  const headerStyle = `section-header ${cfg.headerclass || ''}`.trim();
  const pStyle = cfg.theme || 'text-gray';

  return (
    <div id={cfg.id} className={sectionStyle}>
      <div className="container-fluid">
        <div className="row">
          <div className={bodyStyle}>
            <h2 className={headerStyle}><span>{cfg.header || cfg.title}</span></h2>
            {renderParagraphs(cfg.text, pStyle, cfg.displayAs)}
          </div>
        </div>
      </div>
    </div>
  );
};

function renderParagraphs(parags, styling, displayType) {
  if (displayType==='list') {
    return <ul>{parags.map((item, index) => <li key={index} className={styling}>{item}</li> )}</ul>;
  } else
  if (displayType==='faq') {
    return (
      <ol>
        {parags.map((item, index) => {
          const [q, ans] = item.split('?');
          return (
            <li key={index}>
              <p className={`faq`}>{q+'?'}</p>
              {faqAnswer(ans, styling)}
            </li>
          );
        })}
      </ol>
    );
  }
  if (!Array.isArray(parags)) return <p>?</p>;
  return parags.map((item, index) => <p key={index} className={styling}>{item}</p> );
}

function addclass(name) { return (name ? ` ${name}` : ''); }

function faqAnswer(text, styling) {
  if (text.indexOf(';')>=0) return text.split(';').map((parag,idx) => <p key={`faqp-${idx}`} className={styling}>{parag}</p>);
  return <p className={styling}>{text}</p>;
}
