/**
 * Layout is a default page wrapper defining the major sections of the site.
 * Supported props: config, ctx, currentpath
 */

import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import Banner from './Banner';
import Nav from './NavToggle';
import BasicSection from './BasicSection';
import ComposedSection from './ComposedSection';
import Footer from './FooterSimple';
import { setLinks } from '../util/pagetools';
import { localizer } from '../util/localizer';

export default function Layout(props) {
  const [pickLocale, setPickLocale] = useState(false);
  const cfg = props.config;
  const localized = localizer(props.ctx);
  const toplinks = setLinks(cfg.sections);
  const footdata = {
    ...cfg.footer,
    toplinks,
    sitename: cfg.sitename,
    siteurl: cfg.siteurl
  };

  const {title, subtitle, startstudy, localeprompt, banner} = cfg;
  const basicSections =  cfg.sections.map((section, index) =>
     (section.data ? <ComposedSection key={section.id} src={section} /> : <BasicSection key={section.id} src={section} />)
  );
  const imgPanel = <div key="imgPanel">
        <StaticImage src='../images/body_1.jpg' alt="" className="mx-auto" />
  </div>;
  const imgPanel2 = <div key="imgPanel2">
    <StaticImage src='../images/body_2.jpg' alt="" className="mx-auto" />
  </div>;
  const imgPanel3 = <div key="imgPanel3"><StaticImage src='../images/body_3.jpg' alt="" className="mx-auto" /> </div>;
  const imgPanel4 = <div key="imgPanel4"><StaticImage src='../images/body_4.jpg' alt="" className="mx-auto" /> </div>;

  basicSections.splice(1, 0, imgPanel);
  basicSections.splice(5, 0, imgPanel2);
  basicSections.splice(8, 0, imgPanel3);
  basicSections.splice(11, 0, imgPanel4);

  const isENlocale = (props.ctx.locale==='en');
  const fixedpanel = {
    src: cfg.fixedpanel,
    btnLabel: cfg.startstudy
  };
  if (!isENlocale) {
    fixedpanel.visibleUpper = 'location-page';
    fixedpanel.src.linkto = '#location-page'
  }

  return (
    <div id="page">
      <div id="layout-root">
        <header>
          <Nav
            toplinks={toplinks}
            sitename={cfg.sitename}
            ctx={props.ctx}
            currentpath={props.currentpath}
            localizerfn={localized}
            siteurl={cfg.siteurl}
            pickLocale={pickLocale}
            setPickLocale={setPickLocale}
          />
          <Banner src={{title, subtitle, startstudy, localeprompt, banner}} setPickLocale={setPickLocale} ctx={props.ctx} />
        </header>
        {props.children}
        <Footer src={footdata} localizerfn={localized} />
      </div>
    </div>
  );
};